<template>
  <v-container fluid>
    <v-layout column>
      <v-flex xs12>
        <v-card class="px-5" color="#FAFAFA" style="margin-bottom:50px;">
          <v-layout row wrap align-center class="py-3">
            <v-autocomplete
              class="ma-2"
              v-model="searchParam.paper_code"
              :items="subWisePepList"
              :item-text="(item) => item.paper_code + ' - ' + item.paper_name"
              item-value="paper_code"
              label="Select Course"
              persistent-hint
              prepend-icon="library_books"
              :loading="paperLoading"
            >
              <template v-slot:selection="data">
                <v-chip
                  color="#EBEBEB"
                  close
                  @input="searchParam.paper_code = ''"
                  :selected="data.selected"
                  class="chip--select-multi"
                  >{{
                    data.item.paper_code + " - " + data.item.paper_name
                  }}</v-chip
                >
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-tile-content v-text="data.item"></v-list-tile-content>
                </template>
                <template v-else>
                  <v-list-tile-content>
                    <v-list-tile-title
                      v-html="
                        data.item.paper_code + ' - ' + data.item.paper_name
                      "
                    ></v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </template>
            </v-autocomplete>
            <v-btn color="primary" fab round small @click="getCandiData()">
              <v-icon center dark>search</v-icon>
            </v-btn>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-card color="white">
      <v-layout row>
        <v-card flat>
          <v-card-text class="title">Viva Mark List ..</v-card-text>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
        <v-btn color="primary" @click="MarkSheet()"
          >Download Viva Mark List</v-btn
        >
        <marksEnrty></marksEnrty>
        <!-- <v-btn color="primary" @click="goPVMarksEntry()">Marks Entry</v-btn> -->
      </v-layout>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="getPvCandidateDataSet.candidates"
      :loading="loading"
      class="elevation-4 mytable"
      hide-actions
    >
      <template v-slot:items="props">
        <td class="text-xs">{{ props.index + 1 }}</td>
        <td class="text-xs-center">{{ props.item.exam_roll_no }}</td>
        <td class="text-xs-center">{{ props.item.reg_no }}</td>
        <td class="text-xs-center">{{ props.item.ac_session }}</td>

        <td class="text-xs-left">
          {{ props.item.name }}
        </td>
        <td class="text-xs-center my--style">
          {{ props.item.mark }}
          <span v-if="props.item.mark"
            >out of {{ props.item.viva_full_marks }}</span
          >
        </td>
        <td class="text-xs-center"></td>
      </template>
    </v-data-table>

    <v-card color="white" style="margin-top:50px;">
      <v-layout row>
        <v-card flat>
          <v-card-text class="title">Edited Data Log</v-card-text>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
      </v-layout>
    </v-card>
    <!-- :total-items="pagination2.totalItems"
      :pagination.sync="pagination2"
      :rows-per-page-items="pagination2.rowsPerPageItems" -->
    <!-- v-if="this.searchParam.subject_code || searchParam.code" -->
    <v-data-table
      :headers="editlogheaders"
      :items="getEditedDataList"
      class="elevation-2"
      :loading="loading2"
      
      hide-actions
    >
      <template v-slot:items="props">
        <!-- <td class="text-xs-center">{{ props.item.course }}</td> -->
        <td class="text-xs-center">{{ props.item.exam_roll }}</td>
        <td class="text-xs-center">{{ props.item.reg_no }}</td>
        <td class="text-xs-center">{{ props.item.session }}</td>
        <td class="text-xs-center">{{ props.item.candidate_type }}</td>
        <td class="text-xs-center">{{ props.item.user_name }}</td>
        <td class="text-xs-center">{{ props.item.old_mark }}</td>
        <td class="text-xs-center">{{ props.item.mark }}</td>
        <!-- <td class="text-xs-center">{{ props.item.created_at }}</td> -->
        <td class="text-xs-center">
          {{ dateFormate(props.item.updated_at) }}
        </td>
        <!-- <td class="text-xs-center">
                <v-btn
                  color="primary"
                  outline
                  round
                  @click="showDetailItem(props.item)"
                >Click to See All The Student Details</v-btn>
            </td>-->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import marksEnrty from "./vivaMarksEntry.vue";
export default {
  components: { marksEnrty },
  data() {
    return {
      loading2:false,
      loading:false,
      previlegedExam: null,
      open: false, // For v-expansion-panel's v-model
      searchParam: {
        exam_code: null,
        year: null,
        center_code: null,
        subject_code: null,
        paper_code: null,
      },
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [10, 25, { text: "All", value: -1 }],
      },
      pagination2: {
        rowsPerPage: 10,
        rowsPerPageItems: [10, 25, { text: "All", value: -1 }],
      },
      getExamList: [], // Replace with API data or mocked data
      getYearList: [], // Replace with API data or mocked data
      getVivaCenterList: [], // Replace with API data or mocked data
      getAllSub: ["Bangla", "English"], // Replace with API data or mocked data
      getVivaSubject: [], // Replace with API data or mocked data
      getVivaPaperItem: [], // Replace with API data or mocked data
      paperLoading: false, // Loading indicator for paper items
      getDataList: [],
      getEditedDataList: [],
      sideTitleOfSearchPanel: "Search Exams", // Example side title
      headers: [
        {
          text: "Sl",
          align: "left",
          value: "examinee_name",
          class: "my-style-2",
          sortable: false,
        },
        {
          text: "Exam Roll",
          align: "center",
          value: "examinee_name",
          class: "my-style-2",
          sortable: false,
        },
        {
          text: "Registration No.",
          align: "center",
          value: "reg_no",
          class: "my-style-2",
          sortable: false,
        },
        {
          text: "Session",
          align: "center",
          value: "reg_no",
          class: "my-style-2",
          sortable: false,
        },
        {
          text: "Examinee Name",
          align: "center",
          value: "examinee_name",
          class: "my-style-2",
          sortable: false,
        },
        {
          text: "viva Mark",
          align: "center",
          value: "id",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          value: "id",
          sortable: false,
        },
      ],
      editlogheaders: [
        { text: "Roll", value: "exam_roll", align: "center", sortable: false },
        {
          text: "Registration No",
          value: "reg_no",
          align: "center",
          sortable: false,
        },
        { text: "Session", value: "session", align: "center", sortable: false },
        {
          text: "Canditate Type",
          value: "candidate_type",
          align: "center",
          sortable: false,
        },
        {
          text: "User Name",
          value: "user_name",
          align: "center",
          sortable: false,
        },
        {
          text: "Previous Mark",
          value: "old_mark",
          align: "center",
          sortable: false,
        },
        { text: "New Mark", value: "mark", align: "center", sortable: false },
        // { text: "Created Date", value: "created_at", align: "center" },
        {
          text: "Edited date",
          value: "updated_at",
          align: "center",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    pagination() {
      this.getCandiData();
    },
  },

  computed: {
    subWisePepList() {
      return this.$store.getters.getPvPepData;
    },
    profile() {
      return this.$store.getters.getCollegeUserProfile;
    },
    getPvCandidateDataSet() {
      return this.$store.getters.getPvCandidateData;
    },
  },

  created() {
    this.getExamPrivData();
    this.getSubjectWisePepData();
  },

  methods: {
    bothSearch() {
      //   this.searchPracticalExamList();
      //   this.searchPracticalEditLog();
    },

    getExamPrivData() {
      this.previlegedExam = JSON.parse(
        localStorage.getItem("current-privilege")
      );
    },

    getSubjectWisePepData() {
      let peram = {};
      peram.exam_code = this.previlegedExam.exam_master.exam_code;
      peram.subject_code = this.previlegedExam.subject.subject_code;
      peram.paper_type = "3";
      console.log("Tanvir! This is peram data", peram);
      this.$store.dispatch("fetchPvPepData", peram).then((rsp)=>{
        this.$store.dispatch("clearPvCandidateData");
      });
    },
    MarkSheet() {
      let peram = {};
      peram.exam_code = this.previlegedExam.exam_master.exam_code;
      peram.exam_year = this.previlegedExam.exam_master.exam_year;
      peram.subject_code = this.previlegedExam.subject.subject_code;
      peram.paper_code = this.searchParam.paper_code;
      peram.college_code = this.profile.college_code;
      peram.download_as = "pdf";

      if (peram.paper_code) {
        this.$store.dispatch("PracticalVivaStudentMarks", peram);
      }
    },

    getCandiData() {
      let peram = {};
      peram.exam_code = this.previlegedExam.exam_master.exam_code;
      peram.exam_year = this.previlegedExam.exam_master.exam_year;
      peram.subject_code = this.previlegedExam.subject.subject_code;
      peram.paper_code = this.searchParam.paper_code;
      peram.college_code = this.profile.college_code;
      console.log("Tanvir! This is candi peram data", peram);
      this.$store.dispatch("fetchPvCandidateData", peram).then((data) => {
        this.loading = false;
        this.sl_count = data.from;
        console.log("Tanvir, This is total", data.total_candidate);
        this.pagination.totalDataItems = data.total_candidate;
      });
    },
  },
};
</script>
<style lang=""></style>
